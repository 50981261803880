<template>
  <div>
    <!-- loader -->
    <PatientGeneralSkeleton v-if="loading" />

    <div class="patient-general" v-else>
      <!-- informed consent -->
      <div
        class="page-segment"
        v-if="
          patient.informedConsent &&
          patient.informedConsent.status &&
          informedConsentData[patient.informedConsent.status]
        "
      >
        <div class="page-segment-content">
          <div class="heading-action">
            <div
              class="heading-small margin-bottom-sm padding-bottom-sm"
              v-text="translation['informed-consent'].title"
            />
            <div
              v-if="
                patient &&
                patient.informedConsent &&
                patient.informedConsent.status !== 'CONTRACT_NOT_SENT'
              "
              @click="toggleClamp = !toggleClamp"
              class="icon-arrow-small-outline-down icon-display cursor-pointer"
              :class="{
                'icon-arrow-small-outline-up': toggleClamp,
                'icon-arrow-small-outline-down': !toggleClamp
              }"
            />
          </div>

          <div class="patient-general-content-text">
            <!-- status -->
            <div class="patient-general-content-text-action">
              <div v-if="informedConsentData[patient.informedConsent.status]">
                <div
                  class="content-title text-info margin-bottom-xs"
                  v-text="translation['informed-consent'].subtitle"
                />
                <Chip
                  size="small"
                  :color="
                    informedConsentData[patient.informedConsent.status].color
                  "
                  :text="
                    informedConsentData[patient.informedConsent.status].label
                  "
                />
              </div>
              <Button
                v-if="patient?.informedConsent?.status === 'ALREADY_SIGNED'"
                icon="icon-eyes-on-outline"
                type="outline"
                @clicked="openInformedConsent"
              />
              <Button
                v-if="
                  patient?.informedConsent?.status === 'WAITING_FOR_SIGNATURE'
                "
                icon="icon-share-off"
                type="outline"
                @clicked="copyUserInformedConsentUrl"
              />
            </div>
            <template v-if="toggleClamp">
              <!-- send at -->
              <template v-if="patient.informedConsent.meta.contract_sent_at">
                <div
                  class="content-title text-info margin-top"
                  v-text="$translations.patients.menu['date-send']"
                />
                <div
                  class="body"
                  v-text="
                    $moment(
                      patient.informedConsent.meta.contract_sent_at
                    ).format('MMM D, YYYY h:mm A')
                  "
                />
              </template>

              <!-- signed at -->
              <template v-if="patient.informedConsent.signed_contract_at">
                <div
                  class="content-title text-info margin-top"
                  v-text="$translations.patients.menu['date-sign']"
                />
                <div
                  class="body"
                  v-text="
                    $moment(patient.informedConsent.signed_contract_at).format(
                      'MMM D, YYYY h:mm A'
                    )
                  "
                />
              </template>
            </template>
          </div>
        </div>
      </div>

      <!-- personal data -->
      <div class="page-segment">
        <div class="page-segment-content">
          <div
            class="heading-small margin-bottom-sm padding-bottom-sm"
            v-text="translation['information-data'].title"
          />
          <!-- name -->
          <div class="patient-general-content-text">
            <span
              class="content-title text-info"
              v-text="translation['information-data']['name-lastname']"
            />
            <span class="body text-info" v-html="patientData.name" />
          </div>

          <!-- document id -->
          <div class="patient-general-content-text margin-top">
            <span
              class="content-title text-info"
              v-text="translation['information-data']['document-id']"
            />
            <span class="body text-info" v-html="patientData.document" />
          </div>

          <!-- date birth -->
          <div class="patient-general-content-text margin-top">
            <span
              class="content-title text-info"
              v-text="translation['information-data']['date-birth']"
            />
            <span class="body text-info" v-html="patientData.birthday" />
          </div>

          <!-- Age -->
          <div class="patient-general-content-text margin-top">
            <span
              class="content-title text-info"
              v-text="translation['information-data'].age"
            />
            <span class="body text-info" v-html="patientData.age" />
          </div>

          <!-- gender -->
          <div class="patient-general-content-text margin-top">
            <span
              class="content-title text-info"
              v-text="translation['information-data'].gender"
            />
            <span class="body text-info" v-html="patientData.gender" />
          </div>
        </div>
      </div>

      <!-- contact info -->
      <div class="page-segment" v-if="false">
        <div class="page-segment-content">
          <div
            class="heading-small margin-bottom-sm padding-bottom-sm"
            v-text="translation['contact-info'].title"
          />
          <div class="patient-general-content margin-bottom">
            <div class="patient-general-content-text">
              <span
                class="content-title text-info"
                v-text="translation['contact-info'].phone"
              />
              <span class="body text-info" v-html="patientData.phoneNumber" />
            </div>
            <Button
              class="patient-general-content-button"
              icon="icon-phone-off"
              type="outline"
              @clicked="buttonAction(phone)"
            />
          </div>

          <div class="patient-general-content">
            <div class="patient-general-content-text">
              <span
                class="content-title text-info"
                v-text="translation['contact-info'].email"
              />
              <span class="body text-info" v-html="patientData.email" />
            </div>
            <Button
              class="patient-general-content-button"
              icon="icon-mail-off"
              type="outline"
              @clicked="buttonAction(email, 'mail')"
            />
          </div>
        </div>
      </div>

      <!-- medical information -->
      <div class="page-segment">
        <div class="page-segment-content">
          <div
            class="heading-small margin-bottom-sm padding-bottom-sm"
            v-text="translation['medical-info'].title"
          />
          <!-- eps -->
          <div class="patient-general-content-text">
            <span
              class="content-title text-info"
              v-text="translation['medical-info'].eps"
            />
            <span class="body text-info" v-html="patientData.eps" />
          </div>
        </div>
      </div>

      <!-- place information -->
      <div class="page-segment">
        <div class="page-segment-content">
          <div
            class="heading-small margin-bottom-sm padding-bottom-sm"
            v-text="translation['place-info'].title"
          />
          <!-- Place of birth -->
          <div class="patient-general-content-text">
            <span
              class="content-title text-info"
              v-text="translation['place-info'].birth"
            />
            <span class="body text-info" v-html="patientData.placeBirth" />
          </div>

          <!-- Place of residence -->
          <div class="patient-general-content-text margin-top">
            <span
              class="content-title text-info"
              v-text="translation['place-info'].residence"
            />
            <span class="body text-info" v-html="patientData.placeResidence" />
          </div>
        </div>
      </div>

      <!-- emergency contact -->
      <div
        class="page-segment"
        v-if="
          patientData.emergency_contact && patientData.emergency_contact.name
        "
      >
        <div class="page-segment-content">
          <div
            class="heading-small margin-bottom padding-bottom-sm"
            v-text="translation['emergency-contact']"
          />
          <div class="patient-general-content margin-bottom">
            <div class="patient-general-content-text">
              <div
                class="content-title text-info"
                v-text="patientData.emergency_contact.name"
              />
              <div class="body text-info" v-html="emergency_contact.phone" />
            </div>
            <Button
              class="patient-general-content-button"
              icon="icon-phone-off"
              type="outline"
              @clicked="buttonAction(patientData.emergency_contact.phone)"
            />
          </div>
        </div>
      </div>

      <!-- matching info -->
      <div
        class="page-segment patient-general-emergency-contact"
        v-if="patient.answers"
      >
        <div class="page-segment-content">
          <div
            class="heading-small margin-bottom"
            v-text="translation['matching-info']"
          />
          <div class="patient-general-matching-info-box padding-all">
            <span
              class="small-tag padding-bottom-sm"
              v-text="patient.answers.reasons.title"
            />
            <div class="chips">
              <Chip
                v-for="(item, index) in patient.answers.reasons.options"
                :key="index"
                class="margin-bottom-sm"
                size="small"
                color="purple"
                :text="item"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Chip } from '@seliaco/red-panda'
import { Browser } from '@capacitor/browser'
import PatientGeneralSkeleton from '@/views/patients/components/skeletons/PatientGeneralSkeleton'
import { mapGetters } from 'vuex'

export default {
  name: 'PatientGeneral',
  components: {
    Button,
    Chip,
    PatientGeneralSkeleton
  },
  data () {
    return {
      translation: this.$translations.patients.general,
      toggleClamp: false
    }
  },
  methods: {
    buttonAction (destination, type) {
      if (type === 'mail') {
        Browser.open({ url: `mailto:${destination}` })
      } else {
        Browser.open({ url: `tel:${destination}` })
      }
    },
    openInformedConsent () {
      this.$router.push({
        name: 'InformedConsent',
        params: {
          patientId: this.patient.id
        },
        query: { back: this.$route.fullPath }
      })
    },
    copyUserInformedConsentUrl () {
      const url = `${process.env.VUE_APP_USERS_URL}/contract/specialist/${this.$store.getters['auth/specialist'].id}`
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.appointment.detail['informed-consent']
              .share
          })
        })
        .catch((error) => {
          this.$toast({
            severity: 'error',
            text: error.message
          })
        })
    }
  },
  computed: {
    ...mapGetters({
      patient: 'patient/get',
      meta: 'patient/meta',
      loading: 'patient/loading'
    }),
    patientData () {
      const empty = `<span class="text-red-110 body"> ${this.translation.empty} </span>`

      let name = `${this.patient.first_name} ${this.patient.last_name}`
      let document = empty
      const birthday = this.meta?.birthday || this.patient.birthday
      const age = this.meta?.age || this.patient.age
      const gender =
        this.$translations.gender[this.meta?.gender || this.patient.gender]

      let phoneNumber = `${this.patient.dial_code} ${this.patient.phone}`
      const email = this.meta?.email || this.patient.email
      const eps = this.meta?.eps || empty
      const placeBirth = this.meta?.placeBirth || empty
      const placeResidence =
        this.meta?.placeResidence || this.patient.address || empty

      if (this.meta?.first_name) {
        name = `${this.meta.first_name} ${this.meta.last_name}`
      }
      if (this.meta?.documentType) {
        document = `${this.meta.documentType}. ${this.meta.documentNumber}`
      }
      if (this.meta?.phone) {
        phoneNumber = `+${this.meta.dial_code} ${this.meta.phone}`
      }

      return {
        name,
        document,
        birthday,
        age,
        gender,
        phoneNumber,
        email,
        eps,
        placeBirth,
        placeResidence
      }
    },
    informedConsentData () {
      return {
        CONTRACT_NOT_SENT: {
          color: 'orange',
          label: this.translation['informed-consent'].status.CONTRACT_NOT_SENT
        },
        WAITING_FOR_SIGNATURE: {
          color: 'blue',
          label:
            this.translation['informed-consent'].status.WAITING_FOR_SIGNATURE
        },
        ALREADY_SIGNED: {
          color: 'green',
          label: this.translation['informed-consent'].status.ALREADY_SIGNED
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.patient-general
  .patient-general-content
    display: flex
    justify-content: space-between
    &-text
      display: flex
      flex-direction: column
      gap: 4px
      overflow: hidden
      &-action
        display: grid
        grid-template-columns: 1fr 52px
        min-height: 56px
        align-items: center
        padding-right: 4px
    &-button
      width: 48px
      height: 48px
  .patient-general-matching-info-box
    background: var(--gray-5)
    border-radius: 16px
    .chips
      margin-top: 8px
      display: flex
      flex-wrap: wrap
.text-info
  overflow: hidden
  text-overflow: ellipsis
.heading-action
  display: flex
  justify-content: space-between
</style>
