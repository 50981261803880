<template>
  <div class="page-segment">
    <div class="page-segment-content patient-skeleton">
      <div class="patient-skeleton-header">
        <Skeleton
          width="96px"
          height="96px"
          border-radius="50%"
          class="patient-skeleton-img"
        />
        <div class="patient-skeleton-info">
          <Skeleton width="150px" height="32px" border-radius="8px" />
          <Skeleton
            v-for="index in 2"
            :key="index"
            width="90px"
            height="24px"
            border-radius="8px"
          />
        </div>
      </div>
      <div class="patient-skeleton-actions">
        <Skeleton
          class="margin-y"
          v-for="index in 2"
          :key="index"
          height="48px"
          width="100%"
          border-radius="8px"
        />
      </div>
      <div class="patient-skeleton-tabs">
        <Skeleton
          class="margin-left"
          width="100px"
          height="38px"
          border-radius="8px"
        />
        <Skeleton
          v-for="index in 2"
          :key="index"
          width="100px"
          height="38px"
          border-radius="8px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton } from '@seliaco/red-panda'
export default {
  name: 'PatientHeaderSkeleton',
  components: { Skeleton }
}
</script>

<style lang="sass" scoped>
.patient-skeleton
  &-header
    display: flex
    padding: 0 16px
  &-info
    display: grid
    gap: 4px
    white-space: nowrap
    margin-left: 20px
    align-self: center
  &-actions
    display: flex
    gap: 10px
    padding: 0 16px
  &-tabs
    display: flex
    gap: 16px
    margin-bottom: -16px
</style>
