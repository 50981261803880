export default {
  data () {
    return {
      options: [
        {
          icon: {
            on: 'icon-person-on',
            off: 'icon-person-off'
          },
          text: this.$translations.profile.tabs.general,
          name: 'general',
          action: () => {
            this.$router.replace({
              query: {
                ...this.$route.query,
                tab: 'general'
              }
            })
          }
        },
        {
          icon: {
            on: 'icon-appointment-on',
            off: 'icon-appointment-off'
          },
          text: this.$translations.profile.tabs.appointments,
          name: 'appointments',
          action: () => {
            this.$router.replace({
              query: {
                ...this.$route.query,
                tab: 'appointments'
              }
            })
          }
        },
        {
          icon: {
            on: 'icon-clipboard-on',
            off: 'icon-clipboard-off'
          },
          text: this.$translations.profile.tabs['medical-history'],
          name: 'medical',
          action: () => {
            this.$router.replace({
              query: {
                ...this.$route.query,
                tab: 'medical'
              }
            })
          }
        }
      ]
    }
  }
}
