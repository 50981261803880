<template>
  <div class="margin-top">
    <div class="page-segment" v-for="(appointment, index) in appointments"  :key="index">
      <div class="page-segment-content">
        <div class="heading-small margin-bottom" v-text="appointment.date" />
        <div
          class="card-section"
          v-for="(day, i) in appointment.days"
          :key="i"
        >
          <div class="card-section-date">
            <div class="content-title" v-text="day.day" />
            <div class="body-small" v-text="day.weekday" />
          </div>
          <div class="card-section-container">
            <SpecialistAppointmentCard v-for="(entry, e) in day.entries" :key="e" class="card-section-item" :appointment="entry"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpecialistAppointmentCard from '@/components/cards/v2/SpecialistAppointmentCard'

export default {
  name: 'AppointmentList',
  components: { SpecialistAppointmentCard },
  props: {
    appointments: Array,
    loading: Boolean
  }
}
</script>

<style lang="sass" scoped>
.page-segment
  margin-bottom: 8px
.card-section
  display: flex
  gap: 8px
  &-container
    display: flex
    flex-direction: column
    width: 100%
  &-item
    width: 100%
  &-date
    min-width: 30px
</style>
