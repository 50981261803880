<template>
  <div class="page-segment">
    <div class="page-segment-content">
      <div class="margin-bottom">
        <Skeleton width="100px" height="30px" borderRadius="8px" />
      </div>
      <div class="list-skeleton margin-bottom">
        <div>
          <Skeleton class="margin-bottom-zero" width="18px" height="12px" borderRadius="8px" />
          <Skeleton width="24px" height="12px" borderRadius="8px" />
        </div>
        <div style="width: 100%">
          <Skeleton v-for="index in 3" class="margin-bottom" :key="index" width="100%" height="107px" borderRadius="16px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton } from '@seliaco/red-panda'
export default {
  name: 'PatientAppointmentsSkeleton',
  components: { Skeleton }
}
</script>

<style lang="sass" scoped>
.list-skeleton
  display: flex
  gap: 8px
</style>
