<template>
  <ModalFields
    height="516px"
    :title="$translations.patients.tags.title"
    @close-action="closeModal"
  >
    <template v-slot:content>
      <div v-if="loading" class="tag-field">
        <Skeleton
          class="skeleton"
          v-for="index in 4"
          :key="index"
          height="48px"
          width="100%"
        />
      </div>

      <div v-else class="tag-field">
        <template v-for="(item, index) of tags">
          <div
            class="tag-field-container"
            :key="index + 'container'"
            @click="updateTag(item.id)"
            :class="{ selected: tag && item.tag && tag.id === item.tag.id }"
          >
            <div
              class="icon-tag-filled icon-display"
              :class="`text-${item.color.toLowerCase()}`"
              :key="index + 'tag'"
            />
            <div class="tag-field-item" v-text="item.name" :key="index" />
          </div>
          <div class="tag-field-divisor" :key="index + 'divisor'" />
        </template>
      </div>
    </template>
  </ModalFields>
</template>

<script>
import { ModalFields, SpecialistsPatients, Skeleton } from '@seliaco/red-panda'

export default {
  name: 'PatientTagsModal',
  components: {
    ModalFields,
    Skeleton
  },
  props: {
    tag: Object
  },
  data () {
    return {
      tags: [],
      loading: false
    }
  },
  mounted () {
    this.getTags()
  },
  methods: {
    getTags () {
      this.loading = true
      SpecialistsPatients.getTags()
        .then((tags) => {
          this.tags = [...tags]
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeModal (event) {
      if (event) {
        this.$emit('close', event)
      } else {
        this.$emit('close')
      }
    },
    updateTag (tagId) {
      const reqBody = {
        patient: this.$route.params.id,
        tag: tagId
      }

      SpecialistsPatients.updatePatient(reqBody)
        .then(() =>
          this.$toast({
            text: this.$translations.success.default,
            severity: 'success'
          })
        )
        .finally(() => {
          // send true to reload information
          this.closeModal(true)
        })
    }
  }
}
</script>
<style lang="sass" scoped>
.skeleton
  margin-bottom: 4px
.tag-field
  width: 100%
  display: flex
  flex-direction: column
  &-container
    display: flex
    padding: 0 16px
    align-items: center
    cursor: pointer
    &:hover
      background: var(--gray-5)
  &-item
    cursor: pointer
    box-sizing: content-box
    padding: 13px 19px
    ::v-deep
      input
        width: 95% !important
      .body
        color: var(--gray-80)
    .selected
      background: var(--purple-50)
  &-divisor
    border-bottom: 1px solid var(--gray-10)
    width: calc(100% - 32px)
    margin: -1px auto
    &:last-child
      border-bottom: none
</style>
