<template>
  <div class="page-segment">
    <div class="page-segment-content">
      <div class="margin-bottom">
        <Skeleton width="100px" height="30px" borderRadius="8px" />
      </div>
      <Skeleton
        v-for="index in 7"
        class="margin-bottom"
        :key="index"
        width="100%"
        height="56px"
        borderRadius="16px"
      />
    </div>
  </div>
</template>

<script>
import { Skeleton } from '@seliaco/red-panda'
export default {
  name: 'MedicalHistorySkeleton',
  components: { Skeleton }
}
</script>
