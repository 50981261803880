<template>
  <div>
    <div class="page-segment">
      <div class="page-segment-content">
        <div class="title-skeleton margin-bottom">
          <Skeleton width="100px" height="30px" borderRadius="8px" />
          <Skeleton width="60px" height="20px" borderRadius="8px" />
        </div>
        <div class="list-skeleton">
          <div style="width: 100%">
            <Skeleton width="100%" height="107px" borderRadius="16px" />
          </div>
        </div>
      </div>
    </div>
    <div class="page-segment">
      <div class="page-segment-content">
        <div class="info-skeleton">
          <Skeleton width="100px" height="30px" borderRadius="8px" />
          <div class="info-skeleton-list" v-for="index in 2" :key="index">
            <Skeleton class="margin-top" width="180px" height="52px" borderRadius="8px" />
            <Skeleton class="margin-top" width="48px" height="52px" borderRadius="8px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton } from '@seliaco/red-panda'
export default {
  name: 'PatientGeneralSkeleton',
  components: { Skeleton }
}
</script>

<style lang="sass" scoped>
.title-skeleton
  display: flex
  justify-content: space-between
.info-skeleton
  display: flex
  flex-direction: column
  &-list
    display: flex
    justify-content: space-between
</style>
