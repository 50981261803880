<template>
  <div>
    <!-- loader -->
    <PatientAppointmentsSkeleton v-if="loading" />

    <!-- list -->
    <AppointmentList
      v-if="appointments && !loading"
      :appointments="appointments"
    />

    <!-- datazero -->
    <DataZeroSimple
      v-if="appointments.length <= 0 && !loading"
      :text="datazero.text"
      :image="datazero.image"
    />
  </div>
</template>

<script>
import { SearchSpecialist, SpecialistsPatients } from '@seliaco/red-panda'
import AppointmentList from '@/views/agenda/components/v2/AppointmentList'
import DataZeroSimple from '@/components/data-zeros/v2/DataZeroSimple'
import PatientAppointmentsSkeleton from '@/views/patients/components/skeletons/PatientAppointmentsSkeleton'

export default {
  name: 'PatientAppointments',
  components: {
    AppointmentList,
    DataZeroSimple,
    PatientAppointmentsSkeleton
  },
  data () {
    return {
      appointments: [],
      loading: false,
      datazero: {
        image: SearchSpecialist,
        text: this.$translations['data-zero']['consultant-appointments']
      }
    }
  },
  created () {
    this.get()
  },
  methods: {
    get () {
      this.loading = true

      SpecialistsPatients.getProfileAppointments(this.$route.params.id)
        .then((response) => {
          this.appointments = response
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
