<template>
  <PageContainer
    :title="title"
    :back="actions.back"
    :action="actions.tag"
    :ellipsis="true"
  >
    <template v-slot:content>
      <div v-if="patientProfile">
        <!-- header: avatar, name and speciality -->
        <div
          class="page-segment padding-bottom-zero padding-x-zero margin-bottom-zero"
        >
          <div class="page-segment-content">
            <div class="patient-header padding-x">
              <!-- profile picture -->
              <div class="patient-img">
                <img class="img" :src="avatar" :alt="patientProfile.title" />
                <Chip
                  v-if="patientProfile.tag && patientProfile.tag.name"
                  class="chip"
                  size="small"
                  :color="`${patientProfile.tag.color.toLowerCase()}`"
                  :text="patientProfile.tag.name"
                />
              </div>

              <!-- info -->
              <div class="patient-info">
                <div class="heading" v-text="patientProfile.title" />

                <!-- gender and birthday -->
                <div
                  class="body"
                  v-if="patientProfile.first_line"
                  v-text="patientProfile.first_line"
                />

                <!-- country -->
                <div
                  class="body"
                  v-if="patientProfile.second_line"
                  v-text="patientProfile.second_line"
                />
              </div>
            </div>

            <!-- action buttons -->
            <div class="patient-actions padding-all">
              <Button
                :text="$translations.profile.write"
                icon="icon-message-circle-off"
                :icon-left="true"
                type="outline"
                @clicked="goToMessage"
              />
              <Button
                :text="$translations.appointment.create.submit"
                icon="icon-appointment-off"
                :icon-left="true"
                @clicked="goToSchedule"
              />

              <!-- TODO: enable when need update user data -->
              <div class="patient-actions-menu">
                <Button
                  icon="icon-dots-on"
                  type="outline"
                  @clicked="toggleModalAux"
                />

                <MenuDialog
                  :options="menuOptions"
                  @close="() => toggleModalAux(false)"
                  v-if="openModalAux"
                />
              </div>
            </div>

            <!-- Slider Nav -->
            <SliderNav
              :options="options"
              :route="$route"
              :enable-padding="true"
            />
          </div>
        </div>

        <!-- sections -->
        <div class="margin-top">
          <div class="sections">
            <PatientGeneral v-if="$route.query.tab === 'general'" />
            <PatientAppointments v-if="$route.query.tab === 'appointments'" />
            <MedicalHistory
              :profile="true"
              v-if="$route.query.tab === 'medical'"
            />
          </div>
        </div>
      </div>

      <!-- skeleton -->
      <div class="patient-skeleton" v-if="headerLoading">
        <PatientHeaderSkeleton />

        <div>
          <PatientGeneralSkeleton v-if="$route.query.tab === 'general'" />
          <PatientAppointmentsSkeleton
            v-if="$route.query.tab === 'appointments'"
          />
          <MedicalHistorySkeleton v-if="$route.query.tab === 'medical'" />
        </div>
      </div>

      <!-- modal -->
      <PatientTagsModal
        :tag="patientProfile.tag"
        v-if="openModal && patientProfile"
        @close="closeModal($event)"
      />
    </template>
  </PageContainer>
</template>

<script>
import {
  SpecialistsPatients,
  PageContainer,
  SliderNav,
  Button,
  Chip,
  MenuDialog,
  SpeContracts
} from '@seliaco/red-panda'
import PatientGeneral from '@/views/patients/components/PatientGeneral'
import PatientTagsModal from '@/views/patients/modal/PatientTagsModal'
import MedicalHistory from '@/views/medical-history/MedicalHistory'
import PatientAppointments from '@/views/patients/components/PatientAppointments'
import PatientGeneralSkeleton from '@/views/patients/components/skeletons/PatientGeneralSkeleton'
import PatientAppointmentsSkeleton from '@/views/patients/components/skeletons/PatientAppointmentsSkeleton'
import PatientHeaderSkeleton from '@/views/patients/components/skeletons/PatientHeaderSkeleton'
import MedicalHistorySkeleton from '@/views/medical-history/components/MedicalHistorySkeleton.vue'
import PatientProfileMixin from '@/mixins/patient-profile-mixin'
import { PatientDetail } from '@/types/events'
import { mapGetters } from 'vuex'

export default {
  name: 'PatientDetail',
  components: {
    PageContainer,
    SliderNav,
    Button,
    Chip,
    MenuDialog,
    PatientTagsModal,
    PatientGeneral,
    MedicalHistory,
    PatientAppointments,
    PatientGeneralSkeleton,
    PatientAppointmentsSkeleton,
    PatientHeaderSkeleton,
    MedicalHistorySkeleton
  },
  mixins: [PatientProfileMixin],
  data () {
    const actions = {
      back: {
        callback: () => {
          if (this.$route.query.back) {
            this.$router.replace({
              path: this.$route.query.back
            })
          } else {
            this.$router.replace({
              name: 'Home'
            })
          }
        }
      },
      tag: {
        callback: () => this.addTag(),
        icon: 'icon-tag'
      }
    }

    return {
      patientId: this.$route.params.id,
      menuOptions: [],
      actions,
      patientProfile: null,
      headerLoading: false,
      openModal: false,
      openModalAux: false,
      loading: false,
      patientLocal: null
    }
  },
  created () {
    this.$router.replace({
      query: {
        ...this.$route.query,
        tab: this.$route.query.tab || 'general'
      }
    })
    this.$store.commit('medical/SET_APPOINTMENT', {
      appointment: null
    })

    this.getPatient()
  },
  methods: {
    informConsent () {
      this.loading = true
      /* segment event */
      PatientDetail.consentSend({
        origin: false,
        patient_id: this.patientId,
        specialist_id: this.$route.params.id,
        status:
          this.patientLocal?.informedConsent?.status || 'CONTRACT_NOT_SENT'
      })
      SpeContracts.sendInformedConsent(this.$route.params.id)
        .then(async () => {
          this.$toast({
            severity: 'success',
            text: this.$translations.success.default
          })
          await this.getPatient()
        })
        .catch((error) => {
          this.$toast({
            severity: 'error',
            text: error.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getPatient () {
      this.headerLoading = true
      await this.$store.dispatch('patient/getPatientProfileGeneral', {
        patientId: this.patientId,
        specialistId: this.$store.getters['auth/specialist'].id
      })

      this.patientProfile = await SpecialistsPatients.getProfile(this.patientId)

      this.headerLoading = false
    },
    goToMessage () {
      this.$router.push({
        name: 'Messages',
        query: {
          back: this.$route.fullPath,
          members: JSON.stringify([
            this.$route.params.id,
            this.$store.getters['auth/user'].id
          ])
        }
      })
    },
    goToSchedule () {
      this.$router.push({
        name: 'AppointmentCreate',
        query: { patient_id: this.patientId, selected_patient: true }
      })
      PatientDetail.Profile_Schedule_Appointment({
        patient_id: this.patientId
      })
    },
    addTag () {
      this.openModal = true
    },
    closeModal (event) {
      this.openModal = false
      if (event) {
        this.patientProfile.tag = null
        this.getPatient()
      }
    },
    toggleModalAux (open = true) {
      this.openModalAux = open
    },
    updateMenuOptions () {
      this.menuOptions = [
        {
          name: this.$translations.patients.menu.update,
          icon: 'icon-refresh',
          action: () => {
            this.$router.push({
              name: 'PatientForm',
              params: {
                patientId: this.$route.params.id
              }
            })
          }
        },
        {
          name: !this.loading
            ? this.$translations.patients.menu.consent
            : this.$translations.patients.menu.sending,
          icon: 'icon-bx-send',
          action: () => (!this.loading ? this.informConsent() : null),
          disable:
            this.patientLocal?.informedConsent?.status !==
              'CONTRACT_NOT_SENT' || this.loading,
          loading: this.loading
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      patient: 'patient/get',
      settings: 'settings/getSettings',
      user: 'auth/user'
    }),
    avatar () {
      return (
        this.patientProfile?.picture || require('@/assets/images/avatar.png')
      )
    },
    title () {
      return this.patientProfile?.title || ''
    }
  },
  watch: {
    patient: {
      handler (patient) {
        this.patientLocal = patient
        this.updateMenuOptions()
      },
      immediate: true,
      deep: true
    },
    loading: {
      handler () {
        this.updateMenuOptions()
      },
      immediate: true
    }
  }
}
</script>
<style lang="sass" scoped>
.patient-header
  display: flex
  padding-bottom: 0
  .patient-img
    display: flex
    flex-direction: column
    align-items: center
    .img
      height: 96px
      width: 96px
      max-width: 96px
      object-position: center
      object-fit: cover
      border-radius: 50%
    .chip
      margin-top: -16px

  .patient-info
    display: grid
    gap: 4px
    white-space: nowrap
    margin-left: 20px
    align-self: center
    .heading
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap

.patient-actions
  display: grid
  grid-template-columns: 1fr 1fr 48px
  gap: 8px
  &-menu
</style>
