<template>
  <div v-if="appointment">
    <AppointmentCard
      class="card"
      v-selectable-container="{
        callback: () => navigateAppointmentDetail(appointment.id),
        elementLevel: 1
      }"
      :title="appointment.title"
      :showImage="false"
      :first-line="appointment.first_line"
      :middle-line="appointment.second_line"
      :bottom-line="appointment.third_line"
      :button-action="action(appointment)"
      :active="active(appointment)"
      :service-icon="appointment.icon_name"
      :arrow-action="{
        callback: () => navigateAppointmentDetail(appointment.id)
      }"
    />

    <!-- conclude modal -->
    <CenteredDialog
      v-if="showConcludeModal"
      :title="$translations.appointment.action['conclude']"
      :options="concludeOptions"
      icon="icon-appointment-on"
      @close="showConcludeModal = false"
    />
  </div>
</template>

<script>
import AppointmentDetailMixin from '@/mixins/appointment-detail-mixin'
import { Appointment } from '@/types/events'
import {
  Appointments,
  selectableContainer,
  AppointmentCard,
  CenteredDialog
} from '@seliaco/red-panda'

export default {
  name: 'SpecialistsAppointmentCard',
  mixins: [AppointmentDetailMixin],
  props: {
    appointment: Object,
    showArrow: Boolean
  },
  components: {
    AppointmentCard,
    CenteredDialog
  },
  directives: { selectableContainer },
  computed: {
    action () {
      return (appointment) => {
        switch (appointment.status) {
          case 'PENDING':
          case 'PAYMENT_DECLINED':
            return null

          case 'CONFIRM':
          case 'IN_WAITING_ROOM':
          case 'IN_PROGRESS_WAITING':
            if (this.active(appointment) && appointment.virtual) {
              return {
                text: this.$translations.appointment.attend,
                callback: () => {
                  Appointment.ClickStart(appointment)
                  this.$router.push({
                    name: 'Lobby',
                    params: {
                      id: appointment.id
                    }
                  })
                }
              }
            }

            return null

          case 'IN_PROGRESS':
            if (appointment.virtual) {
              return {
                text: this.$translations.appointment.attend,
                callback: () => {
                  this.$router.push({
                    name: 'Lobby',
                    params: {
                      id: appointment.id
                    }
                  })
                }
              }
            } else {
              return {
                text: this.$translations.appointment.action.conclude,
                callback: () => {
                  this.onConcludeAppointment()
                }
              }
            }

          case 'NO_ATTEND_USER':
            return {
              text: this.$translations.buttons['new-request'],
              callback: () => {
                this.$router.push({
                  name: 'AppointmentCreate',
                  params: {
                    id: this.appointment.specialist.id
                  },
                  query: {
                    patient_id: this.appointment.patient.id,
                    origin_appointment_id: this.appointment.id
                  }
                })
              }
            }

          case 'NON_COMPLETED':
          case 'NO_ATTEND_SPECIALIST':
          case 'NO_ATTEND_USER_WITH_RESCHEDULE':
            return {
              text: this.$translations.appointment['action-sheet'].reschedule,
              callback: () => {
                this.$globalEvent.$emit('modal/reschedule', {
                  showDialog: { reschedule: { open: true, close: false } },
                  appointment: appointment
                })
              }
            }
        }
        return null
      }
    },
    active () {
      return (appointment) => {
        const preAllowedStatus = [
          'IN_WAITING_ROOM',
          'IN_PROGRESS_WAITING',
          'IN_PROGRESS'
        ]

        if (preAllowedStatus.includes(appointment.status)) {
          return true
        }

        if (!this.isAboutToStart()) {
          return null
        }

        const allowedStatus = ['CONFIRM']
        return allowedStatus.includes(appointment.status)
      }
    },
    serviceIcon () {
      return (appointment) => {
        return appointment?.icon_name
      }
    }
  },
  data () {
    return {
      showConcludeModal: false,
      concludeOptions: [
        {
          text: this.$translations.appointment.action.concluded,
          style: 'FILLED',
          action: () => {
            this.changeAppointmentStatus('COMPLETED')
            this.showConcludeModal = false
            this.navigateAppointmentDetail()
          }
        },
        {
          text: this.$translations.appointment.action['no-attended'],
          style: 'FILLED',
          severity: 'DANGER',
          action: () => {
            this.changeAppointmentStatus('NO_ATTEND_USER')
            this.showConcludeModal = false
            this.navigateAppointmentDetail()
          }
        },
        {
          text: this.$translations.general.cancel,
          style: 'OUTLINED',
          action: () => {
            this.showConcludeModal = false
          }
        }
      ]
    }
  },
  methods: {
    onConcludeAppointment () {
      this.showConcludeModal = true
    },
    changeAppointmentStatus (status) {
      Appointments.update(this.appointment.id, {
        status
      })
        .then(() => {
          this.$toast({
            text: this.$translations.success.default,
            severity: 'success'
          })

          this.$emit('changed')
        })
        .catch(() => {
          this.$toast({
            text: this.$translations.error.default,
            severity: 'error'
          })
        })
    },
    navigateAppointmentDetail (appointmentId) {
      this.$router.push({
        name: 'AppointmentDetail',
        params: { id: appointmentId },
        query: {
          back: this.$route.fullPath
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.card
  width: 100%
  margin-bottom: 8px
</style>
